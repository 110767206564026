<template>
    <Dashboard />
</template>

<script>
import Dashboard from '@apps/Dashboard'
import pageMeta from '@/mixins/pageMeta'
export default {
    mixins: [pageMeta],
    components: {
        Dashboard
    }
}
</script>