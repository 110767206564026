<template>
    <component :is="dashboardLayout" />
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ...mapState({
            isMobile: state => state.isMobile
        }),
        dashboardLayout() {
            if(this.isMobile) {
                return () => import('./components/List.vue')
            } else {
                return () => import('./components/Desktop.vue')
            }
        }
    }
}
</script>